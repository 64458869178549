import $ from "jquery";

//Menu On Hover = dropdown show
$('body').on('mouseenter mouseleave', '.nav-item', function (e) {
  if ($(window).width() > 750) {
    var _d = $(e.target).closest('.nav-item'); _d.addClass('show');
    setTimeout(function () {
      _d[_d.is(':hover') ? 'addClass' : 'removeClass']('show');
    }, 1);
  }
});



if ($('header').hasClass('transparentHeader')) {
  $('.navbar-toggler').click(function () {
    if (!$('header').hasClass('dontRemove')){
    $('header').toggleClass('transparentHeader')
    }
    $('header').toggleClass('dontAdd')
  });

  const nav = $('header');
  
  // if ($(document).scrollTop() > nav.height()) {
  //     nav.removeClass('transparentHeader');
  // } else {
  //     nav.addClass('transparentHeader');
  // }
  
  $(window).scroll(() => {
      if ($(document).scrollTop() > nav.height()) {
        nav.removeClass('transparentHeader');
        nav.addClass('dontRemove');
      } else {
          if (!$('header').hasClass('dontAdd')){
          nav.addClass('transparentHeader');
        }

        nav.removeClass('dontRemove');
      }
  });
}